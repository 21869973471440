import { reportClientError } from '/machinery/reportClientError'

export function track(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) return reportClientError(`No data given to track function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}
