import styles from './ButtonPlay.css'
import { useEnteredState } from '/machinery/useEnteredState'
import { Icon } from '/features/buildingBlocks/Icon'
import playButton from '/images/icons/play.raw.svg'

export function ButtonPlay({ onClick, text, relativeToParent = undefined }) {
  const { ref: buttonRef, entered, focused, hovered } = useEnteredState()

  return (
    <div ref={buttonRef} className={cx(styles.component, relativeToParent && styles.relativeToParent)}>
      <div className={cx(
        styles.heading,
        focused && styles.isFocused,
        entered && styles.isEntered,
        hovered && styles.isHovered,
        styles.relativeToParent)}
      >
        <button
          type='button'
          data-x='play-button'
          className={cx(styles.link, hovered && styles.isHovered, styles.relativeToParent)}
          {... { onClick }}
        >
          {text}
        </button>
      </div>

      <span className={cx(styles.icon, hovered && styles.isHovered)}>
        <Icon icon={playButton} />
      </span>
    </div>
  )
}
