import ReactDOM from 'react-dom'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'
import { useSpring, animated, config } from 'react-spring'
import { usePortalNode } from '/machinery/usePortalNode'
import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'
import cross from '/images/icons/cross.raw.svg'

import styles from './Modal.css'

export function Modal({ visible, children, onClose }) {
  const { __ } = useTranslate()
  const portalNode = usePortalNode(styles._rootComponent)
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: visible })
  const animatedBackground = useSpring({
    opacity: visible ? 1 : 0,
    config: config.gentle,
  })

  useEscape(onClose)

  return portalNode && ReactDOM.createPortal(
    <animated.div style={{ ...animatedBackground, pointerEvents: visible ? 'auto' : 'none' }} className={styles.component} ref={bodyScrollLockRef} aria-hidden={visible}>
      <div className={styles.inner}>
        <div className={styles.close}><CloseButton onClick={() => onClose()} /></div>
        {children}
      </div>
      <button
        type='button'
        onClick={onClose}
        className={styles.backdrop}
        aria-label={__`sluit-popup`}
        data-x='close-modal'
      />
    </animated.div>,
    portalNode
  )
}

function CloseButton({ onClick }) {
  const { __ } = useTranslate()
  return (
    <button className={styles.componentCloseButton} type='button' {... { onClick }} aria-label={__`sluit-popup`} data-x='close-modal'>
      <Icon icon={cross} />
    </button>
  )
}

function useEscape(callback) {
  const callbackRef = React.useRef(null)
  callbackRef.current = callback

  React.useEffect(
    () => {
      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)

      function handleKeyDown(e) {
        if (['Esc', 'Escape'].includes(e.key)) {
          callbackRef.current()
        }
      }
    },
    []
  )
}
