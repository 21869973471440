import styles from './InfoBlock.css'
import star from '/images/star.png'
import { LinkButtonPrimary } from '/features/buildingBlocks/Link'

export function InfoBlock({
  text,
  buttonHref = undefined,
  buttonTitle = undefined,
  layoutClassName = undefined
}) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <img className={styles.star} src={star} alt='' />
      <p className={styles.text}>{text}</p>
      {buttonHref && (
        <div className={styles.button}>
          <LinkButtonPrimary href={buttonHref} dataX='link-to-linkedin' target='_blank'>
            {buttonTitle}
          </LinkButtonPrimary>
        </div>
      )}
    </div>
  )
}
