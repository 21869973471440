import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useReportError } from '/machinery/ReportError'
import { track } from '/machinery/tracking/track'
import ReactPlayerBase from 'react-player/lazy'
import { lerp } from '@kaliber/math'

import styles from './VideoPlayer.css'

const playerConfig = {
  vimeo: {
    playerOptions: {
      controls: true,
      playsinline: true,
      title: false,
    },
  },
}

export function VideoPlayer({
  url,
  onProgressChange = undefined,
  playing = undefined,
  onSeek = undefined,
  eventHandlers: {
    onProgress,
    onStart,
    ...eventHandlers
  },
  videoRef = undefined
}) {
  const isMounted = useRenderOnMount()
  const [identity, setIdentity] = React.useState(0)
  const reportError = useReportError()

  return (
    <div className={styles.component}>
      <div className={styles.videoWrapper}>
        {isMounted && (
          <ReactPlayerBase
            key={identity}
            className={styles.video}
            config={playerConfig}
            onError={reportError}
            controls
            onStart={() => {
            // This fixes an issue where react-player plays your video,
            // regardless if you've set playing to false while it was loading
              if (!playing) setIdentity(x => x + 1)
              onStart()
            }}
            onProgress={(progress) => {
              onProgress(progress)
              onProgressChange && onProgressChange(progress.playedSeconds)
            }}
            ref={videoRef}
            {...{ url, playing, onSeek, ...eventHandlers }}
          />
        )}
      </div>
    </div>
  )
}

/**
 * @param {{ name:string, type: 'normal' | 'questionnaire' }} params
 */
export function useVideoTracking({ name, type }) {
  /** @type {React.MutableRefObject<{ name:string, type: string, duration?: number, progress?: number }>} */
  const videoMetadataRef = React.useRef({ name, type })

  /* eslint-disable react-hooks/exhaustive-deps */
  const trackEvent = useCallback((event, additionalVideoMetadata = undefined) => {
    track({
      event,
      metadata: {
        video: {
          ...videoMetadataRef.current,
          ...additionalVideoMetadata
        }
      }
    })
  })

  return {
    eventHandlers: {
      onStart: useCallback(() => trackEvent('video_start')),
      onPlay: useCallback(() => trackEvent('video_play')),
      onEnded: useCallback(() => trackEvent('video_ended')),
      onPause: useCallback(() => trackEvent('video_paused')),
      onDuration: useCallback(duration => {
        videoMetadataRef.current = { ...videoMetadataRef.current, duration }
      }),
      onProgress: useCallback(({ played }) => {
        const progress = 20 * Math.round(lerp({ start: 0, end: 5, input: played, clamp: true }))
        if (!progress) return

        const progressChanged = progress !== videoMetadataRef.current.progress
        if (!progressChanged) return

        videoMetadataRef.current = { ...videoMetadataRef.current, progress }
        trackEvent('video_progress')
      }),
    },
    trackEvent,
  }
  /* eslint-enable react-hooks/exhaustive-deps */
}

function useCallback(f) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(f, [])
}
