export function useEnteredState() {
  const [hovered, setHovered] = React.useState(false)
  const [focused, setFocused] = React.useState(false)

  const nodeRef = React.useRef(null)
  const ref = React.useCallback(
    (node) => {
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        node.addEventListener('focus', handleFocus)
        node.addEventListener('blur', handleBlur)
        nodeRef.current = node
      } else {
        const node = nodeRef.current
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
        node.removeEventListener('focus', handleFocus)
        node.removeEventListener('blur', handleBlur)
        nodeRef.current = null
      }

      function handleMouseOver() { setHovered(true) }
      function handleMouseOut() { setHovered(false) }
      function handleFocus() { setFocused(true) }
      function handleBlur() { setFocused(false) }
    },
    []
  )

  return React.useMemo(
    () => ({
      ref,
      hovered,
      focused,
      entered: hovered || focused
    }),
    [ref, hovered, focused]
  )
}
